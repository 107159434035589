<template>
  <ExportTreeSelect
    :show-icon="false"
    name="media"
    class="custom-select"
    :tree-data="mediaTreeDataComputed"
    :selected="mediaTreeSelected"
    :initially-check-all="false"
    @onChange="onMediaSelect"
    :show-search="true"
  ></ExportTreeSelect>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
// import helper from '@/services/helper';

import ExportTreeSelect from '@/components/TreeSelect/ExportTreeSelect.vue';

export default {
  components: {
    ExportTreeSelect,
  },
  emits: ['onChangeMedia'],
  setup(props, { emit }) {
    const store = useStore();
    const ownMediaData = computed(() => store.getters['config/ownMediaData']);
    const defaultMediaSelected = ref([]);

    const defaultChoices = computed(() =>
      ownMediaData.value.map((v) => {
        return { label: v.name, value: v.id };
      })
    );

    const onMediaSelect = (mList) => {
      console.log('onMediaSelect', mList);
      let result = [];
      let mediaList = [...mList];
      for (let media of mediaList) {
        if (media) {
          const splitted = media.split('::');
          if (splitted && splitted[1]) {
            result.push(splitted[1]);
          } else if (splitted && splitted[0]) {
            result.push(splitted[0]);
          } else {
            result.push(media);
          }
        }
      }
      emit('onChangeMedia', result);
    };

    const mediaTreeDataComputed = computed(() => {
      if (defaultChoices.value && defaultChoices.value.length > 0) {
        const result = [];
        for (let data of defaultChoices.value) {
          let obj = {
            title: '',
            key: '',
            children: [],
            // slots: {
            //   title: 'title',
            // },
          };
          obj.title = data.label;
          obj.key = data.value;
          obj.value = obj.key;
          result.push(obj);
        }
        return result;
      }
      return [];
    });

    const mediaTreeSelected = computed(() => {
      const result = [];
      if (defaultMediaSelected.value && defaultMediaSelected.value.length > 0) {
        for (let data of defaultMediaSelected.value) {
          result.push(data);
        }
      } else {
        for (let data of mediaTreeDataComputed.value) {
          result.push(data.key);
        }
      }
      return result;
    });

    watch(defaultMediaSelected, () => {
      console.log(defaultMediaSelected);
    });

    return {
      mediaTreeDataComputed,
      mediaTreeSelected,
      onMediaSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
  }
}
</style>
