<template>
  <div class="export-history-table">
    <div class="export-header px-16">
      <span>{{ helper.numeral(dataSource.length) }} Files</span>
      <div class="export-header-right-actions">
        <span>Sort by:</span>
        <a-dropdown>
          <div class="sortby cursor-pointer">
            <span class="placeholder">Created Date</span>
            <DownOutlined class="light-gray" />
          </div>
          <template #overlay>
            <a-menu v-model:selectedKeys="selectedSortBy" :multiple="true">
              <li class="ant-dropdown-menu-item dropdown-group">SORT BY</li>
              <a-menu-item key="sortby:created_date">
                Created Date
              </a-menu-item>
              <hr />
              <li class="ant-dropdown-menu-item dropdown-group">ORDER</li>
              <a-menu-item @click="changeOrderBy('order:asc')" key="order:asc">
                Ascending
              </a-menu-item>
              <a-menu-item
                @click="changeOrderBy('order:desc')"
                key="order:desc"
              >
                Descending
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="row-wrapper mt-3">
      <div
        class="row-info px-16"
        v-for="record in dataSource"
        :key="record.key"
      >
        <div class="file-info">
          <div><FileTypeIcon :iconName="record.file_type" /></div>
          <div>
            <div class="file-name">{{ record.file_name }}</div>
            <div class="date">{{ dateStr(record.created_at) }}</div>
          </div>
        </div>
        <div>
          <div
            v-if="['generated', 'downloaded'].includes(record.exported_status)"
            class="actions"
          >
            <div>
              <a-tooltip>
                <template #title>{{
                  record.exported_status === 'downloaded'
                    ? 'Download again'
                    : 'Download'
                }}</template>

                <a-button
                  @click="downloadFile(record)"
                  :type="
                    record.exported_status === 'downloaded' ? '' : 'primary'
                  "
                  class="border-1"
                >
                  <InlineSvg
                    v-if="record.exported_status === 'downloaded'"
                    :src="
                      require('../../assets/images/icon/export-redownload.svg')
                    "
                  />
                  <InlineSvg
                    v-else
                    :src="
                      require('../../assets/images/icon/export-download.svg')
                    "
                  />
                </a-button>
              </a-tooltip>
            </div>
            <a-dropdown placement="bottomRight">
              <FeatherIcon
                class="cursor-pointer"
                type="more-vertical"
                color="#9299B8"
                size="24"
              />
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="removeExport(record)"
                    ><FeatherIcon type="trash" size="12" /> Delete
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div v-else>
            <a-tooltip placement="topRight" title="Generating...">
              <a-spin :indicator="indicator" />
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
    <RemoveExport
      :visible="exportVisible"
      :file-detail="fileDetail"
      @close="toggleExportModal"
    />
  </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
import { ref, toRefs, reactive, computed, h } from 'vue';
import api from '@/services/api';
import helper from '@/services/helper';
import RemoveExport from '@/components/Modal/RemoveExport.vue';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons-vue';
import FileTypeIcon from '../Icon/FileTypeIcon.vue';
import _ from 'lodash';

export default {
  name: 'ExportHistoryTable',
  components: {
    RemoveExport,
    FileTypeIcon,
    InlineSvg,
    DownOutlined,
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    exportHistoryData: {
      type: Array,
      default: () => [],
    },
    isMobileScreen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reload'],
  setup(props, { emit }) {
    const { exportHistoryData } = toRefs(props);
    const columns = ref([]);
    const dataSource = computed(() => {
      let data = exportHistoryData.value;
      if (selectedSortBy.value.includes('order:asc')) {
        data = _.orderBy(data, ['created_at'], ['asc']);
      } else if (selectedSortBy.value.includes('order:desc')) {
        data = _.orderBy(data, ['created_at'], ['desc']);
      }
      return data;
    });
    const exportVisible = ref(false);
    const fileDetail = reactive({
      file_name: '',
      file_id: '',
    });

    const indicator = h(LoadingOutlined, {
      spin: true,
      style: {
        fontSize: '24px',
      },
    });
    const selectedSortBy = ref(['sortby:created_date', 'order:desc']);

    const sortedInfo = ref();

    const columns_table = computed(() => {
      return [
        {
          title: 'Name',
          dataIndex: 'file_name',
          key: 'file_name',
          slots: { customRender: 'file_name' },
          ellipsis: true,
        },
        {
          title: 'Status',
          dataIndex: 'exported_status',
          key: 'exported_status',
          slots: { customRender: 'exported_status' },
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          sorter: helper.getTableSortFn('date-x', 'created_at'),
          defaultSortOrder: 'descend',
          slots: { customRender: 'created_at' },
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          key: 'actions',
          slots: { customRender: 'action' },
        },
      ];
    });

    const columns_table_mobile = computed(() => {
      return [
        {
          title: 'Name',
          dataIndex: 'file_name',
          key: 'file_name',
          sorter: helper.getTableSortFn('string', 'file_name'),
          slots: { customRender: 'file_name' },
          ellipsis: true,
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          className: 'column-action-mobile',
          key: 'actions',
          slots: { customRender: 'action' },
        },
      ];
    });

    const handleChange = (pagination, filters, sorter) => {
      sortedInfo.value = sorter;
      emit('reload', pagination.current);
    };

    const dateStr = (created_at) => {
      return helper.formatDate(created_at, 'MMM D, YYYY');
    };

    const downloadFile = async (item) => {
      const { _id, exported_status, account } = item;
      if (exported_status === 'generated') {
        await api.setDownloadedStatus(_id).catch(() => {});
      }
      window.open(
        `/services/export-download?id=${_id}&account=${account}`,
        '_blank'
      );
      emit('reload', 1);
    };

    const removeExport = (item) => {
      fileDetail.file_name = item.file_name;
      fileDetail.file_id = item._id;
      exportVisible.value = true;
    };

    const toggleExportModal = ({ isReload }) => {
      if (isReload) {
        emit('reload', 1);
      }
      exportVisible.value = false;
    };

    const changeOrderBy = (key) => {
      let group = key.split(':', -1)[0];
      selectedSortBy.value = selectedSortBy.value
        .filter((v) => !v.startsWith(group))
        .concat(key);
    };

    return {
      changeOrderBy,
      indicator,
      columns,
      dataSource,
      handleChange,
      dateStr,
      downloadFile,
      removeExport,
      exportVisible,
      toggleExportModal,
      fileDetail,
      columns_table,
      columns_table_mobile,
      selectedSortBy,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';

.export-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: $gray-color;
}
.row-info {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $border-color-light;
  padding-top: 12px;
  padding-bottom: 12px;
  &:last-child {
    border: none;
  }

  .file-name {
    color: $gray-800;
    font-weight: 500;
  }
  .date {
    color: $light-color;
  }
}
.file-info {
  display: flex;
  gap: 8px;
}
.actions {
  display: flex;
  gap: 8px;
  align-items: baseline;
}
.border-1 {
  border-width: 1px;
}
.sortby {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 9px 12px 9px 12px;
  gap: 8px;

  border: 1px solid $border-color-normal;
  border-radius: 4px;
  .placeholder {
    font-weight: 500;
  }
}
.export-header-right-actions {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ant-dropdown-menu {
  li:hover {
    background: unset;
  }

  :deep(.ant-dropdown-menu-item-selected) {
    color: $primary-color;
    background-color: rgba(51, 113, 255, 0.05);
  }
}

.ant-dropdown-menu-item.dropdown-group {
  font-weight: 500;
  font-size: 11px;
  color: $light-color;
}
.light-gray {
  color: $light-color;
}
hr {
  border: 0;
  background-color: $border-color-light;
  height: 1px;
}
</style>
