<template>
  <a-dropdown
    v-model:visible="visible"
    :id="name"
    :trigger="['click']"
    placement="bottomCenter"
  >
    <!-- <a-input :placeholder="getPlaceHolder" class="detail-input"></a-input>111 -->
    <a-select-v4
      class="custom-select"
      mode="multiple"
      style="width: 100%"
      :options="selectedList"
      v-model:value="selectedValue"
      :allowClear="false"
      :open="false"
      @deselect="initSelect"
      :maxTagCount="maxTagCount"
    >
      <template #maxTagPlaceholder="omittedValues">
        <span>+{{ helper.numeral(omittedValues.length) }}</span>
      </template>
      <template #tagRender="{ label, option }">
        <a-tag
          class="ant-select-selection-item custom-tag"
          :style="
            option.color
              ? {
                  'background-color': helper.hexToRGB(option.color, 0.15),
                  color: option.color,
                }
              : {}
          "
        >
          {{ label }}&nbsp;&nbsp;
        </a-tag>
      </template>
    </a-select-v4>
    <template #overlay>
      <a-menu>
        <div class="search-input-wrapper" v-if="showSearch">
          <a-input
            v-model:value.trim="search"
            :allowClear="true"
            class="search-input"
            placeholder="Search"
            ><template #prefix>
              <FeatherIcon type="search" size="16" color="#5a5f7d" /> </template
          ></a-input>
        </div>
        <div class="select-all-wrapper">
          <a-checkbox
            v-model:checked="isCheckAll"
            class="check-all"
            @change="onCheckAll"
            :indeterminate="indeterminate"
          >
            Select All</a-checkbox
          >
        </div>

        <a-tree-v4
          v-model:checkedKeys="tmpState"
          :height="400"
          :virtual="false"
          checkable
          :selectable="false"
          :tree-data="dataList"
          @check="onCheckTree"
          class="bg-light"
        >
          <template #title="{ key, title, children }">
            <span v-if="showIcon" class="tree-icon">
              <img
                v-if="key === 'facebook'"
                class=""
                src="../../assets/images/source-icon/transparent/facebook.svg"
              />
              <font-awesome-icon :icon="faXTwitter" v-if="key === 'twitter'" />
              <img
                v-if="key === 'instagram'"
                class=""
                src="../../assets/images/source-icon/transparent/instagram.svg"
              />
              <img
                v-if="key === 'youtube'"
                class=""
                src="../../assets/images/source-icon/transparent/youtube.svg"
              />
              <img
                v-if="key === 'blockdit'"
                class="icon-blockdit"
                :src="
                  require('@/assets/images/source-icon/Blockdit Primary Logo.png')
                "
              />
              <img
                v-if="key === 'pantip'"
                class=""
                src="../../assets/images/source-icon/transparent/pantip.svg"
              />
              <font-awesome-icon :icon="faTiktok" v-if="key === 'tiktok'" />

              <img
                v-if="key === 'website' || title === 'Website'"
                class=""
                src="../../assets/images/source-icon/transparent/website.svg"
              />
            </span>
            <span class="tree-title">
              {{ key === 'twitter' ? 'X (Twitter)' : title }}
            </span>
            <span v-if="children && children.length" class="tree-subtitle"
              >({{ children.length }})
            </span>
          </template>
        </a-tree-v4>
        <div class="apply" v-if="mustApply">
          <a-button class="btn-clear" @click="clearSelection"
            >Clear selection</a-button
          >
          <a-button class="btn-apply" @click="submitCheckbox">Apply</a-button>
        </div>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Select, Tree } from 'ant-design-vue-v4';
import { ref, toRefs, onMounted, watch, computed } from 'vue';
import {
  // faTwitter,
  // faFacebook,
  // faYoutube,
  // faInstagram,
  faXTwitter,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import helper from '../../services/helper';
import _ from 'lodash';
// import { useStore } from 'vuex';
// import helper from '@/services/helper';

export default {
  components: {
    'a-select-v4': Select,
    'a-tree-v4': Tree,
  },
  props: {
    showIcon: Boolean,
    initiallyCheckAll: { type: Boolean, default: true },
    name: {
      type: String,
      default: '',
    },
    treeData: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    mustApply: { type: Boolean },
    showSearch: { type: Boolean },
    maxTagCount: { type: null, default: 'responsive' },
  },
  emits: ['onChange'],
  setup(props, { emit }) {
    const indeterminate = ref(false);
    const visible = ref(false);
    const selectedValue = ref([]);
    const treeDataMap = computed(() => {
      return _.keyBy(treeData.value, 'key');
    });

    const selectedList = computed(() => {
      let data = [];
      if (name.value === 'source') {
        let countWebsite = 0;
        for (let source of localState.value) {
          let arr = source.split('::', -1);
          let social = arr[0];
          if (social.toLowerCase() === 'website') {
            countWebsite++;
          } else {
            data.push({ label: helper.capitalize(social), value: social });
          }
        }
        if (countWebsite > 0) {
          let websiteTxt = `Website: All (${countWebsite})`;
          data.push({ label: websiteTxt, value: 'website' });
        }
      } else if (name.value === 'media') {
        let map = localList.value.reduce((prev, curr) => {
          return { ...prev, [curr.key]: curr.title };
        }, {});
        for (let id of localState.value) {
          data.push({ label: map[id], value: id });
        }
      } else if (name.value === 'category') {
        for (let source of localState.value) {
          let arr = source.split('::', -1);
          let label = arr[1];
          let info = treeDataMap.value[source];
          data.push({
            label: info.title,
            value: label,
            color: treeDataMap.value[source].color,
          });
        }
      }

      return data;
    });

    const dataList = computed(() => {
      let haveChildren = localList.value
        .filter((v) => v.hasChild)
        .map((v) => {
          let children = v.children.map((child) => {
            if (
              !child.title.toLowerCase().includes(search.value.toLowerCase())
            ) {
              return { ...child, style: { display: 'none' } };
            } else {
              delete child['style'];
              return child;
            }
          });

          let allNone = children.every(
            (child) => child.style && child.style.display === 'none'
          );

          if (allNone) {
            return { ...v, style: { display: 'none' }, children };
          } else {
            return { ...v, children };
          }
        });

      let noChildren = localList.value
        .filter((v) => !v.hasChild)
        .map((v) => {
          if (!v.title.toLowerCase().includes(search.value.toLowerCase())) {
            v = { ...v, style: { display: 'none' } };
          } else {
            delete v['style'];
          }
          return v;
        });

      for (let child of haveChildren) {
        noChildren = noChildren.concat(child);
      }
      return noChildren;
    });
    const search = ref('');
    // const store = useStore();
    // const sourceTreeData = computed(() => store.getters['account/sourceTreeData']);
    const { treeData, selected, initiallyCheckAll, name, mustApply } =
      toRefs(props);
    // let isCheckAll = true;
    let isCheckAll = ref(false);

    const tmpState = ref([]);
    const localState = ref([]);
    const localList = ref([]);
    const defaultState = ref([]);

    const onCheckAll = (value) => {
      if (value.target.checked) {
        tmpState.value = [...defaultState.value];
      } else {
        tmpState.value = [];
      }
    };

    const onCheckTree = () => {
      tmpState.value = tmpState.value.filter((item) => item !== '');
      tmpState.value = defaultState.value.filter((item) =>
        tmpState.value.includes(item)
      );
    };

    const getPlaceHolder = computed(() => {
      return localState.value.length + ' item selected';
    });

    // const isSelectAll = computed(() => {
    //   console.log('isSelectAll');
    //   let result = {};
    //   Object.keys(defaultState).forEach((k) => {
    //     console.log(k);
    //     if (defaultState[k] && localState[k]) {
    //       console.log(defaultState[k], localState[k]);
    //       result[k] = defaultState[k].length === localState[k].length;
    //     } else {
    //       result[k] = false;
    //     }
    //   });
    //   console.log('result', result);
    //   return result;
    // });

    // Initialize
    const initTree = () => {
      if (treeData && treeData.value) {
        // isCheckAll.value = true;
        localList.value = treeData.value;
        defaultState.value = [...selected.value];
        // defaultState = JSON.parse(JSON.stringify(sourceTreeData.value.defaultState));
      }
    };
    onMounted(() => {
      initTree();
      //temporary fix uncheck all value. current defaultState, localList, and localState is very bad practice that needs to be fix in the future
      if (selected.value && initiallyCheckAll.value) {
        localState.value = [...selected.value];
        tmpState.value = [...selected.value];
        initSelect();
        emit('onChange', localState.value);
        // Object.assign(localState, selected.value);
      }

      // init first time
      onCheckTree();
    });
    watch(
      () => props.treeData,
      () => {
        initTree();
      }
    );
    watch(
      () => props.selected,
      () => {
        localState.value = [...selected.value];
        tmpState.value = [...selected.value];
      }
    );

    watch(tmpState, () => {
      isCheckAll.value = tmpState.value.length === defaultState.value.length;

      if (
        tmpState.value.length > 0 &&
        tmpState.value.length < defaultState.value.length
      ) {
        indeterminate.value = true;
      } else {
        indeterminate.value = false;
      }

      if (!mustApply.value) {
        localState.value = tmpState.value;
        emit('onChange', localState.value);
      }
      initSelect();
    });

    const clearSelection = () => {
      tmpState.value = defaultState.value;
    };
    const submitCheckbox = () => {
      visible.value = false;
      localState.value = tmpState.value;
      selectedValue.value = selectedList.value.map((v) => v.value);
      emit('onChange', localState.value);
    };

    watch(visible, () => {
      if (visible.value) {
        tmpState.value = localState.value;
      }

      isCheckAll.value = tmpState.value.length === defaultState.value.length;
    });
    const initSelect = () => {
      selectedValue.value = selectedList.value.map((v) => v.value);
    };
    return {
      initSelect,
      clearSelection,
      submitCheckbox,

      isCheckAll,
      onCheckAll,
      onCheckTree,
      localState,
      localList,
      getPlaceHolder,
      defaultState,
      visible,
      search,
      dataList,
      faTiktok,
      faXTwitter,
      indeterminate,
      tmpState,
      selectedList,
      selectedValue,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-input {
  // margin-top: 5px;
  height: 42px;
  border: 1px solid #e3e6ef;
  box-sizing: border-box;
  border-radius: 4px;
}

.check-all {
  padding: 10px 0px 5px 25px;
  font-weight: 500;
}

.tree-icon {
  margin-right: 0.5rem;
}

.icon-blockdit {
  width: 14px;
  height: 14px;
}

.tree-title {
  margin-right: 0.5rem;
  font-size: 14px;
  color: #5a5f7d;
}

.tree-subtitle {
  font-size: 12px;
  color: #9299b8;
}

.search-input-wrapper {
  padding: 0.5rem;
}

.search-input {
  height: 2rem;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(227, 230, 239, 1);
  background-color: unset;
  :deep(input.ant-input) {
    background-color: unset !important;
  }
}
.select-all-wrapper {
  border: 1px solid rgba(241, 242, 246, 1);
  border-left: 0px;
  border-right: 0px;
}
.apply {
  border: 1px solid rgba(241, 242, 246, 1);
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.bg-light {
  background-color: rgba(244, 245, 247, 0.5);
}

.btn-apply {
  background-color: #20c997;
  color: white;
  font-size: 12px;
  height: 30px;
}
.btn-clear {
  font-size: 12px;
}
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
  }
}
.custom-tag {
  border-radius: 4px;
  border: 0;
  align-items: baseline;
}

.max-tag {
  border-radius: 20px;
}

.tree-container {
  max-height: 200px;
  overflow-y: auto;
}

:deep(
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
  ) {
  .ant-select-selection-item {
    border-radius: 20px;
  }
}
</style>
