<template>
  <a-radio-group class="radio" :name="name">
    <a-radio
      v-for="opt in options"
      :key="opt.label"
      :value="opt.value"
      :disabled="opt.disabled"
    >
      <div class="wrapper">
        <FileTypeIcon
          class="icon"
          :class="{ disabled: opt.disabled }"
          v-if="opt.icon"
          :iconName="opt.icon"
        />
        {{ opt.label }}
      </div>
    </a-radio>
  </a-radio-group>
</template>

<script>
import FileTypeIcon from '../Icon/FileTypeIcon.vue';
export default {
  props: {
    name: String,
    options: { type: Array, default: () => [] },
  },
  components: { FileTypeIcon },
  setup() {},
};
</script>

<style lang="scss" scoped>
.radio {
  display: flex;

  :deep(.ant-radio-wrapper) {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-radius: 4px;
    border: 1px solid rgba(227, 230, 239, 1);
    &.ant-radio-wrapper-checked {
      border: 1px solid rgba(51, 113, 255, 1);
      background: rgba(51, 113, 255, 0.05);
    }

    & .wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
