<template>
  <a-select
    v-model:value="contentTypeSelected"
    :getPopupContainer="(trigger) => trigger.parentNode"
    class="custom-select"
    mode="multiple"
    style="width: 100%"
    :options="contentTypeOptions"
    @change="onContentTypeSelect"
  >
  </a-select>
  <!-- <a-select class="detail-select" v-model:value="messageTypeSelected" mode="tags" style="width: 100%">
    <template #dropdownRender="{}">
      <a-checkbox-group
        class="checkbox-custom vertical"
        v-model:value="messageTypeSelected"
        name="checkboxgroup"
        :options="messageTypeOptions"
        @change="onChange"
      />
    </template>
  </a-select> -->
</template>

<script>
import { ref, onMounted } from 'vue';

const defaultContentTypeSelected = ['text', 'photo', 'video'];
const defaultContentTypeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Photo', value: 'photo' },
  { label: 'Video', value: 'video' },
];

export default {
  emits: ['onChangeContentType'],
  setup(props, { emit }) {
    const contentTypeSelected = ref([...defaultContentTypeSelected]);
    const contentTypeOptions = defaultContentTypeOptions;

    const onContentTypeSelect = () => {
      emit('onChangeContentType', contentTypeSelected.value);
    };

    onMounted(() => {
      // init first time
      emit('onChangeContentType', contentTypeSelected.value);
    });

    return {
      onContentTypeSelect,
      contentTypeOptions,
      contentTypeSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
  }
}
</style>
