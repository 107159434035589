<template>
  <div class="wrapper">
    <div v-for="d in categoryTreeDataComputed" :key="d.key">
      <a-row :gutters="16" class="mb-3">
        <a-col :span="4" class="fw-500">
          <label
            >{{ helper.capitalize(d.key) }}
            <span class="text-muted"
              >({{ helper.numeral(localState[d.level]?.length) }})</span
            ></label
          ></a-col
        >
        <a-col :span="20">
          <ExportTreeSelect
            :show-icon="false"
            name="category"
            :tree-data="d.children"
            :selected="categoryTreeSelected[d.key]"
            max-tatg-count="responsive"
            :internally-check-all="true"
            @onChange="onCategorySelect($event, d.level)"
            :showSearch="true"
            :mustApply="true"
          ></ExportTreeSelect>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';

import ExportTreeSelect from '@/components/TreeSelect/ExportTreeSelect.vue';

export default {
  components: {
    ExportTreeSelect,
  },
  emits: ['onChangeCategory'],
  setup(props, { emit }) {
    const store = useStore();
    const categoryTreeData = computed(
      () => store.getters['config/categoryTreeData']
    );
    // const defaultList = computed(
    //   () => store.getters['config/defaultCategoryVisibleMap']
    // );
    const localState = ref({});

    const getCategory = (state) => {
      let category = [];
      const subCategory = [];
      for (let level in state) {
        const data = state[level];
        let c = data;

        if (Number(level) === 0) {
          category = c;
        } else {
          subCategory.push({
            level: level,
            category: c,
          });
        }
      }
      return { category, subCategory };
    };
    const onCategorySelect = (cList, categoryLevel) => {
      const levelData = {};
      if (cList.length > 0) {
        for (let cat of cList) {
          if (cat) {
            const splitted = cat.split('::');
            const level = splitted[0];
            if (splitted && splitted.length >= 2) {
              if (!levelData[level]) levelData[level] = [];
              levelData[level].push(splitted[1]);
            }
          }
        }
      } else {
        levelData[categoryLevel] = [];
      }

      localState.value = Object.assign(
        {},
        { ...localState.value, ...levelData }
      );

      const { category, subCategory } = getCategory(localState.value);

      emit('onChangeCategory', {
        category,
        subCategory,
      });
    };

    const categoryTreeDataComputed = computed(() => {
      if (categoryTreeData.value && categoryTreeData.value.tree) {
        const result = [];
        for (let data of categoryTreeData.value.tree) {
          let obj = {
            title: '',
            key: '',
            children: [],
            icon: '',
            // slots: {
            //   title: 'title',
            // },
            level: data.level,
          };
          obj.title = data.name;
          obj.key = data.name ? data.name.toLowerCase() : '';
          obj.value = obj.key;
          // obj.value = data.children && data.children.length ? '' : data.name;
          // obj.key = data.children && data.children.length ? '' : data.name.toLowerCase();
          obj.icon = data.name;

          if (data.children && data.children.length) {
            for (let child of data.children) {
              obj.children.push({
                title: child.label,
                value: child.value,
                key: child.value,
                color: child.color,
              });
            }
          }

          result.push(obj);
        }
        return result;
      }
      return [];
    });

    const categoryTreeSelected = computed(() => {
      const result = {};
      if (categoryTreeData.value && categoryTreeData.value.tree) {
        for (let data of categoryTreeData.value.tree) {
          const list = [];
          if (data.children && data.children.length) {
            for (let child of data.children) {
              list.push(child.value);
            }
          } else {
            list.push(data.name);
          }
          result[data.id.toLowerCase()] = list;
        }
      }
      return result;
    });

    return {
      categoryTreeDataComputed,
      categoryTreeSelected,
      onCategorySelect,
      categoryTreeData,
      helper,
      localState,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '../../../config/theme/colors.json';
.text-muted {
  color: $gray-solid;
}

.wrapper {
  border: 1px solid rgba(241, 242, 246, 1);
  background: rgba(248, 249, 251, 1);
  border-radius: 4px;
  padding: 1rem;
}

.fw-500 {
  font-weight: 500;
}
</style>
